import { defineNuxtPlugin } from "#app"
import { AnalyticsEvent } from "@evercam/shared/types"

export default defineNuxtPlugin((app) => {
  const { $vuetify, $analytics } = app.nuxt2Context
  const refreshThemeMode = () => {
    // check if the user has a preference for dark mode in localStorage
    let localTheme = window.localStorage.getItem("theme")
    if (localTheme) {
      $vuetify.theme.dark = localTheme === "dark"
    }
    // if not, apply dark mode and save it to localStorage
    else {
      $vuetify.theme.dark = true
      window.localStorage.setItem("theme", "dark")
    }
  }
  const switchTheme = (
    { isDark, disablePersistToLocalStorage } = {
      isDark: null,
      disablePersistToLocalStorage: null,
    }
  ) => {
    if (isDark !== null) {
      $vuetify.theme.dark = isDark
    }

    if (disablePersistToLocalStorage == null) {
      const mode = $vuetify.theme.dark ? "dark" : "light"
      const localTheme = window.localStorage.getItem("theme")
      if (mode === localTheme) {
        return
      }
      window.localStorage.setItem("theme", mode)
      $analytics.saveEvent(AnalyticsEvent.layoutThemeMode, { mode })
    }
  }
  refreshThemeMode()
  app.provide("theme", { refreshThemeMode, switchTheme })
})

import {
  AnalyticsEvent,
  AnalyticsRequestPayload,
} from "@evercam/shared/types/analytics"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { defineNuxtPlugin, useNuxtApp } from "#app"
export default defineNuxtPlugin((app) => {
  const {
    $errorTracker,
    $device,
    isDev,
    $config,
    $permissions,
    $posthog,
    $auth,
    env,
  } = app.nuxt2Context

  const analytics = {
    saveEvent: (eventId, extraParams = {}) => {
      const accountStore = useAccountStore()
      const cameraStore = useCameraStore()

      const params: AnalyticsRequestPayload = {
        name: eventId,
        cameraExid: cameraStore.selectedCameraExid,
        ...extraParams,
      }

      const pageId = useNuxtApp()?.nuxt2Context?.route?.meta[0]?.pageId
      if (!pageId) {
        console.error(
          `Missing page ID for page: ${
            useNuxtApp()?.nuxt2Context?.route?.name
          },  event "${eventId}"!`
        )

        return new Promise((resolve) => resolve({}))
      }

      let finalEventId = eventId
      if (eventId === AnalyticsEvent.pageView) {
        finalEventId = `${pageId}-${eventId}`
      }

      const finalParams = {
        ...extraParams,
        pageId,
      }

      if (isDev || env.NUXT_ENV_VERCEL_ENV === "preview") {
        console.log("AnalyticsEvent: ", finalEventId, " params: ", finalParams)
      }

      $posthog.capture(finalEventId, finalParams)

      if (
        isDev ||
        $permissions.user.is.admin() ||
        accountStore.isApiLogin ||
        accountStore.isImpersonationLogin ||
        !accountStore.email ||
        !$config?.public.analyticsEnabled ||
        !accountStore.token ||
        $auth.isTokenExpired()
      ) {
        return new Promise((resolve) => resolve({}))
      }

      return EvercamApi.analytics
        .sendAnalyticsEvent(params, {
          headers: {
            "x-client-type": $device.context,
          },
        })
        .catch((error) => {
          if (error instanceof Error || typeof error === "string") {
            $errorTracker?.save(error)
          }
        })
    },
  }

  app.provide("analytics", analytics)

  app.hook("app:mounted", () => {
    app.vue2App.$root.$on("analytics-event", ({ eventId, params = {} }) => {
      analytics.saveEvent(eventId, params)
    })
  })
})

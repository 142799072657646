import { defineStore } from "pinia"
import {
  ProgressPhoto,
  ProgressPhotoDialogType,
} from "@evercam/shared/types/progressPhoto"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useNuxtApp } from "#app"

interface ProgressPhotoState {
  progressPhotos: ProgressPhoto[]
  currentProgressPhoto: ProgressPhoto[]
  progressPhotoId: number | null
  progressPhotoDialog: boolean
  progressPhotoDialogType: string | null
}

export const useProgressPhotoStore = defineStore({
  id: "progressPhoto",
  state: (): ProgressPhotoState => ({
    progressPhotos: [],
    currentProgressPhoto: [],
    progressPhotoId: null,
    progressPhotoDialog: false,
    progressPhotoDialogType: ProgressPhotoDialogType.Create,
  }),
  actions: {
    async createProgressPhoto(payload) {
      try {
        await EvercamApi.progressPhotos.create(payload)
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.progress_photo.create_success"
          ) as string
        )
        if (payload?.type) {
          useNuxtApp().nuxt2Context.$analytics.saveEvent(
            `create_snapmail_${payload.provider}`
          )
        }
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.progress_photo.create_failed"
          ) as string,
          error,
        })
      }
    },

    async getProgressPhotos() {
      try {
        const data = await EvercamApi.progressPhotos.index()
        this.progressPhotos = data.progressPhotos
      } catch (err) {
        console.log(err)
      }
    },

    async getCurrentProgressPhoto(id) {
      try {
        const data = await EvercamApi.progressPhotos.show(id)
        this.currentProgressPhoto = data.progressPhotos
      } catch (err) {
        console.log(err)
      }
    },
    async destroyProgressPhoto(id) {
      try {
        await EvercamApi.progressPhotos.delete(id)
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.progress_photo.delete_success"
          ) as string
        )
        this.getProgressPhotos()
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.progress_photo.delete_failed"
          ) as string,
          error,
        })
      }
    },

    async updateProgressPhoto(payload) {
      try {
        await EvercamApi.progressPhotos.update(payload.id, payload.data)
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.progress_photo.update_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.progress_photo.update_failed"
          ) as string,
          error,
        })
      }
    },

    async updatePauseStatus({ id, isPaused }) {
      try {
        await EvercamApi.progressPhotos.update(id, {
          isPaused,
        })
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            isPaused
              ? "content.progress_photo.pause_success"
              : "content.progress_photo.resume_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.progress_photo.update_status_failed"
          ) as string,
          error,
        })
      }
    },
  },
  getters: {
    isEditDialog: (state) =>
      state.progressPhotoDialogType === ProgressPhotoDialogType.Edit,
    isCreateDialog: (state) =>
      state.progressPhotoDialogType === ProgressPhotoDialogType.Create,
  },
})
